




































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import IconPlus from '@/app/ui/assets/ics_f_plus_circle_white.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/cod'
import controller from '@/app/ui/controllers/CODController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { CODPaymentSetting } from '@/domain/entities/COD'
import { Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import { EnumCODPaymentSettingStatus } from '@/app/infrastructures/misc/Constants/cod'
import ModalConfirm from '../components/Modals/ModalConfirm/index.vue'

interface IOption {
  label: string
  value: string | number
}

@Component({
  components: {
    Button,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    LoadingOverlay,
    Badge,
    ModalConfirm,
    IconPlus,
  },
})
export default class CODPaymentSettingList extends Vue {
  controller = controller

  enumCODPaymentStatus = EnumCODPaymentSettingStatus

  statusOptions: IOption[] = [
    {
      label: 'Semua',
      value: this.enumCODPaymentStatus.ALL,
    },
    {
      label: 'Aktif',
      value: this.enumCODPaymentStatus.ACTIVE,
    },
    {
      label: 'Menunggu',
      value: this.enumCODPaymentStatus.WAITING,
    },
    {
      label: 'Kadaluarsa',
      value: this.enumCODPaymentStatus.EXPIRED,
    },
  ]

  perPageOptions: IOption[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  parameters = {
    page: 1,
    perPage: 10,
    status: this.statusOptions[0],
  }

  headers = [
    this.headerCellMapper('No', '60px'),
    this.headerCellMapper('Periode Mulai', '240px'),
    this.headerCellMapper('Periode Berakhir', '240px'),
    this.headerCellMapper('Status', '240px'),
    this.headerCellMapper('Atur', '272px'),
  ]

  listData: ITableCell[][] = []

  isModalConfirmVisible = false
  deletedCodFeeId: number | null = null

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      status: this.parameters.status.value,
    }
  }

  created(): void {
    this.fetchList(true)
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getAllCODPaymentSetting(this.params)
  }

  public onFilterStatus(status: IOption | null): void {
    if (!status) {
      this.parameters.status = this.statusOptions[0]
    }

    this.fetchList(true)
  }

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchList(true)
  }

  public deleteCODFee(): void {
    this.isModalConfirmVisible = false
    this.controller.deleteCODPaymentSetting(<number>this.deletedCodFeeId)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  public formatedStatus(
    status: string
  ): {
    text: string
    type: string
  } {
    let result = {
      text: '-',
      type: '',
    }
    switch (status) {
      case this.enumCODPaymentStatus.ACTIVE:
        result = {
          text: 'Aktif',
          type: 'success',
        }
        break
      case this.enumCODPaymentStatus.EXPIRED:
        result = {
          text: 'Kadaluarsa',
          type: 'error-1',
        }
        break
      case this.enumCODPaymentStatus.WAITING:
        result = {
          text: 'Menunggu',
          type: 'warning',
        }
        break
    }
    return result
  }

  @Watch('controller.codPaymentSettingData')
  onCODPaymentSettingDataChanged(data: CODPaymentSetting[]): void {
    this.listData = data.map((codFee: CODPaymentSetting, index) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1) + '.',
        '60px'
      ),
      this.tableCellMapper(
        codFee.periodeStart
          ? Utils.formatDateWithIDLocale(codFee.periodeStart, 'DD MMMM YYYY')
          : 'Selamanya',
        '240px'
      ),
      this.tableCellMapper(
        codFee.periodeEnd
          ? Utils.formatDateWithIDLocale(codFee.periodeEnd, 'DD MMMM YYYY')
          : 'Selamanya',
        '240px'
      ),
      this.tableCellMapper(<string>codFee.status, '240px'),
      this.tableCellMapper(
        [
          String(<number>codFee.id),
          <string>codFee.status,
          String(<boolean>codFee.isDefault),
        ],
        '272px'
      ),
    ])
  }

  @Watch('controller.isDeleteCODPaymentSettingSuccess')
  onIsDeleteCODPaymentSettingSuccess(data: boolean): void {
    if (data) {
      this.fetchList(true)
      this.controller.setIsDeleteCODPaymentSettingSuccess(false)
    }
  }
}
