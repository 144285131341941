export enum EnumCODPaymentSettingStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  EXPIRED = 'EXPIRED',
}

export enum EnumCODPaymentStatus {
  WAITING = 'WAITING',
  VALID = 'VALID',
  INVALID = 'NOT_VALID',
}

export enum EnumCODPaymentSettingChargedBy {
  CUSTOMER = 'CUSTOMER',
  LIONPARCEL = 'LIONPARCEL',
}

export enum CODPaymmentSortFields {
  POD_DATE = 'pod_date',
  COD_AMOUNT = '',
  COURIER = 'courier',
}

export enum CODPaymmentSortType {
  POD_DATE_ASC = 'pod_date-asc',
  POD_DATE_DESC = 'pod_date-desc',
  COD_AMOUNT_ASC = 'cod_amount-asc',
  COD_AMOUNT_DESC = 'cod_amount-desc',
  COURIER_ASC = 'courier-asc',
  COURIER_DESC = 'courier-desc',
}

export enum EnumSearchBy {
  STT = 'STT',
  COURIER = 'COURIER',
}

export enum EnumPaymentType {
  CASH = 'CASH',
  QRIS = 'QRIS',
}

export enum EnumCODType {
  COD = 'COD',
  DFOD = 'DFOD',
}

export const searchByOptions = [
  {
    label: 'STT',
    value: <string>EnumSearchBy.STT,
  },
  {
    label: 'Courier',
    value: <string>EnumSearchBy.COURIER
  },
]

export const codTypeOptions = [
  {
    label: 'COD',
    value: <string>EnumCODType.COD,
  },
  {
    label: 'DFOD',
    value: <string>EnumCODType.DFOD,
  },
]

export const paymentTypeOptions = [
  {
    label: 'Cash',
    value: <string>EnumPaymentType.CASH,
  },
  {
    label: 'QRIS',
    value: <string>EnumPaymentType.QRIS,
  },
]

export const statusOptions = [
  {
    label: 'Menunggu',
    value: <string>EnumCODPaymentStatus.WAITING,
  },
  {
    label: 'Sesuai',
    value: <string>EnumCODPaymentStatus.VALID,
  },
  {
    label: 'Tidak Sesuai',
    value: <string>EnumCODPaymentStatus.INVALID,
  },
]

export const datepickerPresets = [
  {
    key: 'last7days',
    label: '7 Hari Terakhir',
    value: 7,
  },
  {
    key: 'last31days',
    label: '31 Hari Terakhir',
    value: 31,
  },
]
