import { SubmitCODPaymentSettingRequestInterface, UpdateCODPaymentStatusRequestInterface } from '../contracts/CODRequest'

export class SubmitCODPaymentSettingRequest
  implements SubmitCODPaymentSettingRequestInterface {
  public endPeriod?: string
  public startPeriod?: string
  public chargedFeePercentage?: number
  public chargedFeeBy?: string

  constructor(
    endPeriod?: string,
    startPeriod?: string,
    chargedFeePercentage?: number,
    chargedFeeBy?: string
  ) {
    this.endPeriod = endPeriod
    this.startPeriod = startPeriod
    this.chargedFeePercentage = chargedFeePercentage
    this.chargedFeeBy = chargedFeeBy
  }

  toPayload(): string {
    const payload = {
      start_period: this.startPeriod,
      end_period: this.endPeriod,
      charged_fee_percentage: this.chargedFeePercentage,
      charged_fee_by: this.chargedFeeBy,
    }

    return JSON.stringify(payload)
  }
}

export class UpdateCODPaymentStatusRequest implements UpdateCODPaymentStatusRequestInterface {
  public status?: number
  public reason?: string

  constructor(status?: number, reason?: string) {
    this.status = status
    this.reason = reason
  }

  toPayload(): string {
    const payload = {
      status: this.status,
      reason: this.reason
    }

    return JSON.stringify(payload)
  }
}
