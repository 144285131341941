function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"cod-payment-setting"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('h1',{staticClass:"text-black text-xl font-semibold"},[_vm._v("Kelola Biaya COD")]),_c('Button',{staticClass:"flex flex-row gap-1 text-sm font-semibold",attrs:{"data-testid":"cod-payment-setting-list__create-cod-setting","routeTo":{ name: 'CODPaymentSettingCreate' }}},[_c('IconPlus'),_vm._v(" Biaya COD ")],1)],1),_c('div',{staticClass:"flex flex-col gap-2 mt-8"},[_c('span',{staticClass:"text-sm font-semibold text-bgMatterhorn</template>"},[_vm._v("Status")]),_c('div',{staticClass:"grid grid-cols-8"},[_c('DropdownSelect',{attrs:{"data-testid":"cod-payment-setting-list__status-filter","placeholder":"Pilih Status","options":_vm.statusOptions},on:{"input":_vm.onFilterStatus},model:{value:(_vm.parameters.status),callback:function ($$v) {_vm.$set(_vm.parameters, "status", $$v)},expression:"parameters.status"}})],1)]),_c('div',{staticClass:"cod-payment-setting-table mt-3"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.listData},scopedSlots:_vm._u([{key:"3",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('Badge',{staticClass:"text-sm font-medium w-28 text-center",attrs:{"type":_vm.formatedStatus(data.data.value).type}},[_vm._v(" "+_vm._s(_vm.formatedStatus(data.data.value).text)+" ")])]}},{key:"4",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"flex flex-row text-red-7"},[_c(data.data.value[1] !== _vm.enumCODPaymentStatus.EXPIRED
                ? 'router-link'
                : 'div',{tag:"component",staticClass:"text-sm font-semibold pr-5",class:[
              {
                'text-gray-1 cursor-not-allowed':
                  data.data.value[1] === _vm.enumCODPaymentStatus.EXPIRED,
              } ],attrs:{"to":{
              name: 'CODPaymentSettingEdit',
              params: {
                codFeeId: data.data.value[0],
              },
            }}},[_vm._v("Edit")]),_c('router-link',{staticClass:"text-sm font-semibold px-5",attrs:{"to":{
              name: 'CODPaymentSettingDetail',
              params: {
                codFeeId: data.data.value[0],
              },
            }}},[_vm._v("Lihat Detail")]),(data.data.value[2] !== 'true')?_c('div',{staticClass:"text-sm font-semibold pl-5 cursor-pointer",class:[
              {
                'text-gray-1 cursor-not-allowed':
                  data.data.value[1] === _vm.enumCODPaymentStatus.EXPIRED,
              } ],attrs:{"data-testid":("cod-payment-setting-list__delete-cod-setting-" + (data.index)),"to":""},on:{"click":function () {
                if (data.data.value[1] !== _vm.enumCODPaymentStatus.EXPIRED) {
                  _vm.isModalConfirmVisible = true
                  _vm.deletedCodFeeId = data.data.value[0]
                }
              }}},[_vm._v(" Hapus ")]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"mt-3"},[_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"data-testid":"cod-payment-setting-list__pagination","page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"lang":"id","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"totalItem":_vm.controller.paginationData.total},on:{"input":function () {
          _vm.fetchList()
        },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1),_c('ModalConfirm',{attrs:{"data-testid":"cod-payment-setting-list__modal-confirm","visible":_vm.isModalConfirmVisible,"title":"Yakin Hapus Data Biaya COD?","description":"Data Biaya COD akan hilang","actionBtnLabel":"Ya, Hapus"},on:{"cancel":function () {
        _vm.isModalConfirmVisible = false
        _vm.deletedCodFeeId = null
      },"action":_vm.deleteCODFee}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }