import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from 'vuex-module-decorators'
import store from '../store'
import { Pagination } from '@/domain/entities/Pagination'
import {
  CODPayment,
  CODPaymentDetail,
  CODPaymentSetting,
  CODPaymentSettings,
  CODPaymentSummary,
  CODPayments,
} from '@/domain/entities/COD'
import { CODPresenter } from '../presenters/CODPresenter'
import { container } from 'tsyringe'
import { Utils } from '@/app/infrastructures/misc'
import Vue from 'vue'
import {
  SubmitCODPaymentSettingRequest,
  UpdateCODPaymentStatusRequest,
} from '@/data/payload/api/CODRequest'

export interface CODState {
  isLoading: boolean
  paginationData: Pagination
  codPaymentSettingData: CODPaymentSetting[]
  isSubmitCODPaymentSettingSuccess: boolean
  isPeriodAlreadyExist: boolean
  codPaymentSettingDetailData: CODPaymentSetting
  isDeleteCODPaymentSettingSuccess: boolean
  codPaymentData: CODPayment[]
  codPaymentSummaryData: CODPaymentSummary
  codPaymentDetailData: CODPaymentDetail
  isValidateCODPaymentSuccess: boolean
}

@Module({ namespaced: true, store, name: 'cod', dynamic: true })
class CODController extends VuexModule implements CODState {
  private presenter: CODPresenter = container.resolve(CODPresenter)
  isLoading = false
  paginationData = new Pagination()
  codPaymentSettingData: CODPaymentSetting[] = []
  isSubmitCODPaymentSettingSuccess = false
  isPeriodAlreadyExist = false
  codPaymentSettingDetailData: CODPaymentSetting = new CODPaymentSetting()
  isDeleteCODPaymentSettingSuccess = false
  codPaymentData: CODPayment[] = []
  codPaymentSummaryData: CODPaymentSummary = new CODPaymentSummary()
  codPaymentDetailData: CODPaymentDetail = new CODPaymentDetail()
  isValidateCODPaymentSuccess = false

  @Action({ rawError: true })
  getAllCODPaymentSetting(params: Record<string, string | number>): void {
    this.setIsLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllCODPaymentSetting(formattedParams)
      .then(res => {
        this.setCODPaymentSettingData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage COD Fee Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  createCODPaymentSetting(payload: SubmitCODPaymentSettingRequest): void {
    this.setIsLoading(true)

    this.presenter
      .createCODPaymentSetting(payload)
      .then(() => {
        this.setIsSubmitCODPaymentSettingSuccess(true)
      })
      .catch(error => {
        if (
          error.error.message.en.includes(
            'Setting payment is intersect on setting payment'
          )
        ) {
          this.setIsPeriodAlreadyExist(true)
        }
        Vue.notify({
          title: 'Create COD Fee Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  getCODPaymentSetting(codFeeId: number): void {
    this.setIsLoading(true)

    this.presenter
      .getCODPaymentSetting(codFeeId)
      .then(res => {
        this.setCODPaymentSettingDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch COD Fee Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  updateCODPaymentSetting(params: {
    payload: SubmitCODPaymentSettingRequest
    codFeeId: number
  }): void {
    this.setIsLoading(true)

    this.presenter
      .updateCODPaymentSetting(params.codFeeId, params.payload)
      .then(() => {
        this.setIsSubmitCODPaymentSettingSuccess(true)
      })
      .catch(error => {
        if (
          error.error.message.en.includes(
            'Setting payment is intersect on setting payment'
          )
        ) {
          this.setIsPeriodAlreadyExist(true)
        }
        Vue.notify({
          title: 'Update COD Fee Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  deleteCODPaymentSetting(codFeeId: number): void {
    this.setIsLoading(true)

    this.presenter
      .deleteCODPaymentSetting(codFeeId)
      .then(() => {
        this.setIsDeleteCODPaymentSettingSuccess(true)
        Vue.notify({
          title: 'Delete COD Fee Success',
          text: 'The COD Fee has been deleted',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete COD Fee Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
  }

  @Action({ rawError: true })
  getAllCODPayment(params: Record<string, string | number | boolean>): void {
    this.setIsLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllPayment(formattedParams)
      .then(res => {
        this.setCODPaymentData({ data: res, sync: <boolean>params.syncSummary })
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch COD Payment List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  getCODPayment(packageId: string): void {
    this.setIsLoading(true)

    this.presenter
      .getCODPayment(packageId)
      .then(res => {
        this.setCODPaymentDetailData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch COD Payment Detail Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  validateCODPaymentStatus(params: {
    packageId: string
    payload: UpdateCODPaymentStatusRequest
  }): void {
    this.setIsLoading(true)

    this.presenter
      .updateCODPaymentStatus(params.packageId, params.payload)
      .then(() => {
        this.setIsValidateCODPaymentSuccess(true)
        Vue.notify({
          title: 'Update COD Payment Status',
          text: 'Update COD Payment Status Success',
          type: 'success',
          duration: 5000,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update COD Payment Status Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Action({ rawError: true })
  exportCODPayments(params: Record<string, string | number | boolean>): void {
    this.setIsLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .exportCODPayments(formattedParams)
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export COD Payment List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setIsLoading(false)
      })
  }

  @Mutation
  setIsLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  setCODPaymentSettingData(data: CODPaymentSettings): void {
    this.paginationData = <Pagination>data.pagination
    this.codPaymentSettingData = <CODPaymentSetting[]>data.data
  }

  @Mutation
  setIsSubmitCODPaymentSettingSuccess(bool: boolean): void {
    this.isSubmitCODPaymentSettingSuccess = bool
  }

  @Mutation
  setIsPeriodAlreadyExist(bool: boolean): void {
    this.isPeriodAlreadyExist = bool
  }

  @Mutation
  setCODPaymentSettingDetailData(data: CODPaymentSetting): void {
    this.codPaymentSettingDetailData = data
  }

  @Mutation
  setIsDeleteCODPaymentSettingSuccess(bool: boolean): void {
    this.isDeleteCODPaymentSettingSuccess = bool
  }

  @Mutation
  setCODPaymentData(payload: { data: CODPayments; sync: boolean }): void {
    this.codPaymentData = payload.data.data || []
    this.paginationData = <Pagination>payload.data.pagination
    if (payload.sync) {
      this.codPaymentSummaryData = <CODPaymentSummary>payload.data.summary
    }
  }

  @Mutation
  setCODPaymentDetailData(data: CODPaymentDetail): void {
    this.codPaymentDetailData = data
  }

  @Mutation
  setIsValidateCODPaymentSuccess(bool: boolean): void {
    this.isValidateCODPaymentSuccess = bool
  }
}

export default getModule(CODController)
