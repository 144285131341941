














































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import WarningIcon from '@/app/ui/assets/ics_o_warning_triangles.vue'
import AskIcon from '@/app/ui/assets/warning_icon_modal.vue'

@Component({
  components: {
    Modal,
    Button,
    WarningIcon,
    AskIcon,
  },
})
export default class ModalConfirm extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: 'ask' }) private type!: 'cancel' | 'ask'
  @Prop({ default: 'Title' }) private title!: string
  @Prop({ default: 'Description' }) private description!: string
  @Prop({ default: 'Cek Ulang' }) private cancelBtnLabel!: string
  @Prop({ default: 'Ya, Sesuai' }) private actionBtnLabel!: string
}
